@media (max-width: 768px){
    .container-position{
		margin: 100px auto;
		box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.5);
		padding: 0px;
		width: 90%;
	}
    .illustration{
		position: relative;
		height: 100%;
		background: linear-gradient(to bottom, rgba(1, 41, 112), rgba(37, 118, 184, 0.75));
	}
	.illus-img img{
		display: none;
	}
	.illust-img img{
		display: none;
	}
	.illust-text h4{
		padding-left: 10px;
		padding-top: 10px;
		color: #fff;
		font-size: 5vw;
	}
	.illust-text h4 span{
		color: rgb(201, 149, 29);
	}
	.container-wrapper {
		background: #Fff;
		background-size: cover;
		background-position: center;
		padding: 20px;
	}
	
	i.email {
		right: 20px;
		top: 13px;
		color: rgb(1, 49, 89, 0.5);
	}
	
	input {
		height: 40px;
		text-align: center;
		background: none;
		border: none;
		color: #013159;
		font-size: 12px;
	}
	
	input.email {
		border: 2px solid #013159;
		border-radius: 50px;
	}
	
	i.password {
		left: 20px;
		top: 13px;
		color: rgb(1, 49, 89, 0.5);
	}
	
	input.password {
		border: 2px solid #013159;
		border-radius: 50px;
	}
	
	button.submit {
		font-size: 12px;
		color: #013159;
		background: none;
		border: 2px solid #013159;
		border-radius: 20px;
		padding: 5px 0px;
		width: 100px;
	}
	button.submit:hover {
		color: white;
		background: #013159;
	}
	input[type="checkbox"] {
		border: 2px solid #013159;
		background: none;
	}
	
	label {
		color: #013159;
		font-size: 12px;
	}
	
	button.action {
		font-size: 12px;
		color: #013159;
		background: none;
		border: 2px solid #013159;
		border-radius: 20px;
		padding: 5px 0px;
	}
	button.action:hover {
		color: white;
		background: #013159;
	}
	
	h1 {
		color: #013159;
	}
	
	::-webkit-input-placeholder {
		color: rgb(1, 49, 89, 0.5);
	}
	
	input:focus {
		outline: none;
	}
}